import React from "react"
import Page from "../../../components/page"
import ResponsiveImageGrid from "../../../components/responsive-image-grid"
import { themes } from "../../../styles/themes"
import paths from "../../../utils/paths"

const backRoomGrid = labels => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/back-room/back-room-blue.jpg",
        imgClass: "back-room-img",
        text: labels.backRoomUnlockYourDreamsText,
        textContainerClass: "back-room-unlock-your-dreams-text-container",
        textClass: "back-room-unlock-your-dreams-text",
        alt: labels.backRoomLifeIsACabaretAlt,
        imgTitle: labels.lifeIsACabaretImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 35,
        src: "/images/back-room/hot-stylish-chair-bitch.jpg",
        alt: labels.backRoomHotStylishChairAlt,
        imgTitle: labels.hotStylishChairImgTitle
      },
      {
        type: "image",
        weight: 63.5,
        src: "/images/back-room/boudoir-collection-secret-randezvous.jpg",
        text: labels.backRoomSecretRandezvousText,
        textContainerClass: "back-room-secret-randezvous-text-container",
        textClass: "back-room-secret-randezvous-text",
        alt: labels.backRoomBoudoirCollectionAlt,
        imgTitle: `${labels.boudoirCollectionImgTitle}: ${labels.madameImgTitle}, ${labels.misteryLoungeImgTitle}`
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/back-room/deep-throat-masks-eyes-wide-shut.jpg",
        text: labels.backRoomEyesWideShutText,
        textContainerClass: "back-room-eyes-wide-shut-container",
        textClass: "back-room-eyes-wide-shut-text",
        alt: labels.backRoomDeepThroatAlt,
        imgTitle: labels.deepThroatImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/back-room/mustang-leather-gloves.jpg",
        title: labels.backRoomMustangTitle,
        titleColor: "black",
        alt: labels.backRoomMustangAlt,
        imgTitle: labels.mustangImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/back-room/mustang-tail-plug-detail.jpg",
        text: labels.backRoomDesignIsFunText,
        textClass: "back-room-design-is-fun-text",
        alt: labels.backRoomMustangTailPlugAlt,
        imgTitle: labels.mustangImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/back-room/back-room-xxx-club-gif.gif",
        title: labels.backRoomXXXClubTitle,
        text: labels.backRoomDontThinkGetInText,
        textContainerClass: "back-room-dont-think-get-in-container",
        textClass: "back-room-dont-think-get-in-text",
        alt: labels.backRoomXXXClubAlt,
        imgTitle: `${labels.xxxClubImgTitle}: ${labels.xArmchairImgTitle}, ${labels.moonSideTableImgTitle}, ${labels.coquetteOdetteImgTitle}`
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 66,
        src: "/images/back-room/boudoir-collection-detail.jpg",
        alt: labels.backRoomBoudoirCollectionAlt,
        imgTitle: `${labels.boudoirCollectionImgTitle}: ${labels.madameImgTitle}, ${labels.misteryLoungeImgTitle}`
      },
      {
        type: "image",
        weight: 32.5,
        src: "/images/back-room/xxx-collection-grouped.jpg",
        title: labels.backRoomXXXCollectionTitle,
        alt: labels.backRoomXXXCollectionAlt,
        imgTitle: `${labels.xxxCollectionImgTitle}: ${labels.xxxSofaImgTitle}, ${labels.xxxTabouretCoffeeTableImgTitle}`
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "html",
        weight: 100,
        html: (
          <div className="back-room-sex-text-container">
            <p className="back-room-sex-text-paragraph">
              {labels.backRoomSexText}
            </p>
            <p className="back-room-sex-author-paragraph">
              {labels.backRoomSexAuthor}
            </p>
          </div>
        )
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/back-room/mustang-legs-detail.jpg",
        text: labels.backRoomMarcelloText,
        textClass: "back-room-marcello-text",
        alt: labels.backRoomMustangLegsAlt,
        imgTitle: labels.mustangImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 35,
        src: "/images/back-room/easy-rider-handcuffs-detail.jpg",
        alt: labels.backRoomEasyRiderAlt,
        imgTitle: labels.easyRiderImgTitle
      },
      {
        type: "html",
        weight: 27,
        html: (
          <div className="paragraph-div-centered-relatively">
            <p className="back-room-design-for-sin-text">
              {labels.backRoomDesignForSinText}
            </p>
          </div>
        )
      },
      {
        type: "image",
        weight: 35,
        src:
          "/images/back-room/back-room-life-is-a-cabaret-installation-gif.gif",
        title: labels.backRoomLifeIsACabaretTitle,
        subtitle: labels.backRoomLifeIsACabaretSubtitle,
        alt: labels.backRoomLifeIsACabaretAlt,
        imgTitle: labels.lifeIsACabaretImgTitle
      }
    ]
  }
]

export default function BackRoom({ labels, ...props }) {
  return (
    <Page
      {...props}
      labels={labels}
      title={labels.backRoom}
      theme={themes(labels).ipsBlack(true)}
      description={labels.backRoomDescription}
      keywords={labels.backRoomKeywords}
      ogUrl={paths(labels).backRoom}
      ogImage="/images/og-images/back-room.jpg"
      headerPadding
      footerPadding
    >
      <ResponsiveImageGrid grid={backRoomGrid(labels)} />
    </Page>
  )
}
